<template>
  <b-sidebar
    id="edit-client-sidebar"
    :visible="isEditClientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @change="(val) => $emit('update:is-edit-client-sidebar-active', val)"
  >
    <template
      #default="{ hide }"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ `${t('Edit Client')} #${id}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        v-show="!onSubmitProcess.open"
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <validation-provider
            #default="validationContext"
            :name="t('Type')"
            rules="required"
          >
            <b-form-group
              :label="t('Type')"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="clientData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            :name="t('First Name')"
            rules="required"
          >
            <b-form-group
              :label="t('First Name')"
              label-for="firstname"
            >
              <b-form-input
                id="firstname"
                v-model="clientData.firstname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="First name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Last Name')"
            rules=""
          >
            <b-form-group
              :label="t('Last Name')"
              label-for="surname"
            >
              <b-form-input
                id="surname"
                v-model="clientData.surname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Last name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            :name="t('Phone')"
            rules=""
          >
            <b-form-group
              :label="t('Phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="clientData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            :name="t('Email')"
            rules="email"
          >
            <b-form-group
              :label="t('Email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="clientData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider
            #default="validationContext"
            :name="t('City')"
            rules=""
          ><b-form-group
            :state="getValidationState(validationContext)"
            :label="t('City')"
            label-for="city"
          >
            <v-select
              v-model="clientData.city"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['ANDIJAN','BUKHARA','DJIZZAK','FERGANA','KARAKALPAKISTAN','KASHKADARYA','KHOREZM','NAMANGAN','NAVOI','SAMARKAND','SIRDARYA','SURKHANDARYA','TASHKENT']"
              :clearable="false"
              input-id="city"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <b-form-group
            :label="t('Address')"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="clientData.address"
              type="text"
              placeholder=""
              trim
            />
          </b-form-group>

          <!-- Country -->

          <validation-provider
            #default="validationContext"
            :name="t('Responsible')"
            :rules="clientData.type === 'customer' ? 'required' : ''"
          >
            <b-form-group
              :label="t('Responsible')"
              label-for="responsible"
              :state="getValidationState(validationContext)"
            >
              <v-select
                ref="refRespOpt"
                v-model="clientData.resp_user_id"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users"
                label="name"
                :reduce="val => val.id"
                input-id="responsible"
                :clearable="false"
              >
                <template #list-header>
                  <li
                    v-if="users.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                </template>
                <template #selected-option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
                <template #option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact -->
          <b-form-group
            label="Comments"
            label-for="comments"
          >
            <b-form-textarea
              id="comments"
              v-model="clientData.comments"
              type="text"
              placeholder=""
              trim
            />
          </b-form-group>

          <div class="d-flex justify-content-between align-items-center">
            <label for="discount">{{ t('Discount') }}</label>
            <b-form-checkbox
              v-model="clientData.discount"
              switch
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="onSubmitProcess.save"
            >
              <b-spinner
                v-if="onSubmitProcess.save"
                small
                type="grow"
              />
              {{ t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :disabled="onSubmitProcess.save"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <div
        v-show="onSubmitProcess.open"
      >
        <div class="text-center text-primary my-2">
          <b-spinner
            class="align-middle mr-1"
          />
          <strong>{{ t('Загрузка') }}...</strong>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormTextarea, BAvatar, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    BFormTextarea,
    // BMedia,
    BAvatar,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isEditClientSidebarActive', 'id'],
    event: ['update:is-edit-client-sidebar-active', 'update:id'],
  },
  props: {
    isEditClientSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: () => null,
      // required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()

    const onSubmitProcess = ref({
      save: false,
      open: false,
    })

    const blankClientData = {
      firstname: '',
      surname: '',
      phone: '',
      email: '',
      city: '',
      address: '',
      type: 'customer',
      resp_user_id: '',
      comments: '',
      discount: false,
    }

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)))
    const resetClientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData))
    }

    const users = ref([])
    store.dispatch('app-client/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    watch(() => props.isEditClientSidebarActive, val => {
      if (val === false) {
        emit('update:id', null)
      }
    })

    watch(() => props.id, val => {
      console.log('asdawd', val)
      if (val != null) {
        onSubmitProcess.value.open = true
        store.dispatch('app-client/fetchClient', {
          id: props.id,
        })
          .then(response => {
            const client = response.data.records[0]
            clientData.value = {
              firstname: client.firstname,
              surname: client.surname,
              phone: client.phone,
              email: client.email,
              city: client.city,
              address: client.address,
              type: client.type,
              resp_user_id: client.resp_user_id,
              comments: client.comments,
              discount: client.discount === '1',
            }
          // console.log(response.data.battery.station)
          })
          .catch(error => {
            if (error.response.status === 404) {
              clientData.value = undefined
            }
          })
          .finally(() => {
            onSubmitProcess.value.open = false
          })
      }
    })
    // const full = computed(() => store.getters['app/currentBreakPoint'])
    const onSubmit = () => {
      onSubmitProcess.value.save = true
      clientData.value.discount = clientData.value.discount ? '1' : '0'
      store.dispatch('app-client/updateClient', { id: props.id, data: clientData.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить и продать.',
            },
          })
          emit('refetch-data')
          emit('update:is-edit-client-sidebar-active', false)
        })
        .finally(() => {
          onSubmitProcess.value.save = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClientData)

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'secondary'
    }

    return {
      clientData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      onSubmitProcess,
      t,
      users,
      resolveUserAvatarVariant,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
